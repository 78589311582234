import httpClient  from '../api';
const API_CONTROLLER = 'report-store/';

export default {
    getReportReceipt(search) {
        let url = API_CONTROLLER + 'get-report-receipt';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                storeId: search.storeId,
                productId: search.productId
            }
        });
    },

    getReportExport(search) {
        let url = API_CONTROLLER + 'get-report-export';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                storeId: search.storeId,
                productId: search.productId
            }
        });
    },

    getReportInventory(search) {
        let url = API_CONTROLLER + 'get-report-inventory';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                storeId: search.storeId,
                productId: search.productId
            }
        });
    },

    exportInventory(search) {
        let url = API_CONTROLLER + 'export-report-inventory';
        return httpClient.get(url, {
            params: {
                fromDate: search.fromDate,
                toDate: search.toDate,
                storeId: search.storeId,
                productId: search.productId
            }
        });
    },
}